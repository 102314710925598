<div class="card-main-wrapper" #screen>
  <div class="row row-m-lr-0">
    <div class="col-md-3 pl-0" *ngIf="allowCustomUpload && !isLeftNavClosed">
      <div class="dzoneWrapper">
        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="uploadNewFileBtnPressed($event)"
                       dropZoneClassName="dzone1"
                       contentClassName="dzonecontent1">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div>
              <fa-icon [icon]="faPlus" class="text-white mr-2"></fa-icon>
              <button type="button" class="btn border-0 text-white" (click)="openFileSelector()">New Chat</button>
            </div>
            <div>
              <span class="text-muted">Drop PDF Here</span>
            </div>
          </ng-template>
        </ngx-file-drop>

        <div class="mt-4 mb-2 text-center">
          <div class="row">
            <div class="col-md-12 text-right">
              <button type="button" class="btn btn-outline-primary rounded text-white"
                      (click)="onCreateNewFolderBtnClicked()">
                <fa-icon [icon]="faFolder"></fa-icon>
                New Folder
              </button>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="files-list-group" *ngIf="list">
            <div class="list-group" *ngFor="let item of list">
              <div class="list-group-parent d-flex">
                <div style="width: 92%">
                  <fa-icon [icon]="faFolderPlane"></fa-icon>
                  {{item.name}}
                </div>
                <div class="text-muted float-right" ngbDropdown>
                  <fa-icon [icon]="faDotLine" class="no-caret-dd cursor-pointer" ngbDropdownToggle></fa-icon>
                  <ul ngbDropdownMenu>
                    <li>
                      <a ngbDropdownItem (click)="renameFolder(item)">
                        <fa-icon [icon]="faEdit" class="mr-1"></fa-icon>
                        Rename</a>
                    </li>
                    <li>
                      <a ngbDropdownItem (click)="deleteFolder(item)">
                        <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>
                        Delete</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div [dndDropzone] (dndDrop)="dndOnDropped($event, item.files)" dndEffectAllowed="move">
                <div class="list-group-item dnd-placeholderref" dndPlaceholderRef></div>
                <div class="list-group-item d-flex" *ngFor="let file of item.files"
                     dndEffectAllowed="move"
                     [dndDraggable]="file"
                     (dndMoved)="dndOnDnbDragCb(file, item.files, 'move');"
                     [dndDisableIf]="item.files.length <= 1"
                     (click)="onCurrentActiveSelectedFile(file)"
                     [class.selected]="file === currentActiveSelectedFile"
                >
                  <div style="width: 95%">
                    <span class="text-white ellipsis-95">
                       <fa-icon [icon]="faComment"></fa-icon>
                      {{ file.name }}
                    </span>
                  </div>
                  <div class="text-muted float-right" ngbDropdown>
                    <fa-icon [icon]="faDotLine" class="no-caret-dd" ngbDropdownToggle></fa-icon>
                    <ul ngbDropdownMenu>
                      <li>
                        <a ngbDropdownItem (click)="renameFile(file)">
                          <fa-icon [icon]="faEdit" class="mr-1"></fa-icon>
                          Rename</a>
                      </li>
                      <li>
                        <a ngbDropdownItem (click)="deleteFile(file)">
                          <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>
                          Delete</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{'col-md-5': allowCustomUpload && !isLeftNavClosed, 'col-md-8': !allowCustomUpload || isLeftNavClosed}"
      class="pl-0">
      <div class="pdf-viewer-wrapper">
        <div *ngIf="pdfSrcErr">
          <ngb-alert type="danger" [dismissible]="true" [animation]="true">Error loading PDF</ngb-alert>
        </div>

        <div *ngIf="noFileAvailableToLoad">
          <ngb-alert type="danger" [dismissible]="true" [animation]="true">There is no PDF to load</ngb-alert>
        </div>

        <div class="pdf-viewer-toolbar-top" *ngIf="pdfFullyRendered"
             style="padding-right: 5px;
             padding-top: 5px;
             padding-bottom: 5px;">
          <div class="row">
            <div *ngIf="allowCustomUpload" class="col-md-1 pl-0 pr-0"
                 style="text-align: center; vertical-align: middle;">
               <span class="cursor-pointer" *ngIf="!isLeftNavClosed">
                  <fa-icon [icon]="faArrowLeft" (click)="toggleLeftNav()" style="color: #1078ff"></fa-icon>
                </span>
              <span class="cursor-pointer" *ngIf="isLeftNavClosed">
                  <fa-icon [icon]="faArrowRightCircle" (click)="toggleLeftNav()"></fa-icon>
                </span>
            </div>

            <div class="col-md-5 pl-0 pr-0">
              <!-- Icon + Name -->
              <div class="font-weight-bolder" *ngIf="currentActiveSelectedFile">
                <span class="">
                  <fa-icon [icon]="faFilePdf"></fa-icon>
                </span>
                <span class="filename" style="word-wrap: break-word;">
                {{ currentActiveSelectedFile.name }}
                </span>
              </div>
            </div>

            <!-- Button tool bar-->
            <div class="col-md-6">
              <div class="pdf-toolbar-button-container"
                   style="display: flex; align-items: center; margin-left: auto; float: right">
                <div style="display: inline-flex;">
                  <fa-icon *ngIf="currentPDFPageNumber!==1" [icon]="faArrowAltCircleLeft"
                           class="btn btn-pdf-toolbar"
                           (click)="goToPDFPageNumber(currentPDFPageNumber-1)">
                  </fa-icon>
                  <input type="text" [(ngModel)]="currentPDFPageNumber"
                         (ngModelChange)="goToPDFPageNumber(currentPDFPageNumber)"
                         class="form-control"
                         style="height: 80%; width: 60px"/>
                  <fa-icon [icon]="faArrowAltCircleRight"
                           class="btn btn-pdf-toolbar"
                           (click)="goToPDFPageNumber(currentPDFPageNumber+1)">
                  </fa-icon>
                </div>

                <div>
                  <fa-icon [icon]="faUndo"
                           class="btn btn-pdf-toolbar"
                           (click)="rotatePDF()">
                  </fa-icon>
                </div>
                <div>
                  <fa-icon [icon]="faMinus"
                           class="btn btn-pdf-toolbar"
                           (click)="zoomPDF(0.1,'-')">
                  </fa-icon>
                </div>
                <div>
                  {{this.currentPDFZoom * 100 | number: '1.0-0'}}%
                </div>
                <div>
                  <fa-icon [icon]="faPlus"
                           class="btn btn-pdf-toolbar"
                           (click)="zoomPDF(0.1,'+')">
                  </fa-icon>
                </div>
                <div>
                  <fa-icon style="font-size: 14px;" [icon]="faSnipping"
                           class="btn btn-pdf-toolbar"
                           (click)="toggleSnippingModeActive()"
                           [ngClass]="{'text-success': isSnippingModeActive}"
                           ngbTooltip="Drag over Math or Graph to Explain with GPT-4 Vision" placement="bottom"
                           container="body">
                  </fa-icon>
                </div>
                <div class="pr-3">
                  <input type="text" [(ngModel)]="currentPDFSearchStr"
                         (ngModelChange)="searchSubStrInCurrentPDF(currentPDFSearchStr)"
                         class="form-control"
                         style="height: 80%;"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="pdf-viewer-container">
          <div *ngIf="!pdfFullyRendered  && !noFileAvailableToLoad && !pdfSrcErr" class="subtask-loader">
          </div>
          <pdf-viewer [src]="pdfSrc"
                      [render-text]="true"
                      [original-size]="false"
                      style="width: 100%; height: 100%;"
                      [(page)]="currentPDFPageNumber"
                      [rotation]="currentPDFRotation"
                      [zoom]="currentPDFZoom"
                      (error)="onPDFViewerError($event)"
                      (after-load-complete)="onPDFFullyRenderedAtPDFViewer($event)"
          ></pdf-viewer>
        </div>
      </div>
    </div>
    <div class="col-md-4 pl-0">
      <div class="chat-container" *ngIf="currentActiveSelectedFile && currentActiveSelectedFile.chatPDFSourceId &&
      (!pdfSrcErr && !noFileAvailableToLoad && pdfFullyRendered)">
        <div class="chat-container-wrapper">
          <div class="form-inline chat-toolbar-top">
            <div class="chat-toolbar-top-wrapper">
              <ngb-alert type="danger" [dismissible]="true" [animation]="true" *ngIf="chatErr">{{chatErr}}</ngb-alert>
              <fa-icon class="cursor-pointer"
                       [icon]="faClipboardActiveIcon"
                       (click)="copyAllChatToClipBoard()"
                       (mouseenter)="resetCopyAllChatToClipBoardIcon() "
              ></fa-icon>
            </div>
          </div>

          <div class="messages-container" #scrollMessageContainer>

            <div class="container" *ngIf="faqArr && faqArr.length">
              <div class="text-center">
                <p><img _ngcontent-hnu-c222="" src="../../public/assets/images/bvr_logo_(200_dpi)_highres.jpg"
                        style="width: 35px;"></p>
                <p>How can I help?</p>
              </div>
              <div class="">
                <ng-container *ngFor="let fq of faqArr">
                  <div class=" mb-4">
                    <div class="card cursor-pointer faq-tile" (click)="onFaqQuestionClick(fq)" style="height: 100%;">
                      <div class="card-body">
                        <p class="card-text">{{ fq }}</p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div *ngFor="let message of messagesHistory" class="message mb-5">

              <fa-icon *ngIf="message.query.mid"
                       [icon]="faTrash" class="message-delete-icon float-right mr-4 text-muted cursor-pointer"
                       (click)="deleteOneMessage(currentActiveSelectedFile.chatPDFSourceId,message.query.mid )">
              </fa-icon>
              <div *ngIf="message.query" class="message-user" style="width: 96%">
                <span class="font-weight-bold mr-2">You:</span>
                {{ message.query.content }}
                <p *ngIf="message.query.attachment_image" class="text-right">
                  <img [src]="message.query.attachment_image" style="height: 124px; width: auto"/>
                </p>
              </div>

              <div *ngIf="message.answer" class="message-bot">
                <div>
                  <img style="width: 35px"
                       class="circle-image"
                       src="../../public/assets/images/bvr_logo_(200_dpi)_highres.jpg"/>
                  <span class="font-weight-bold mr-2">RADA:</span>
                  <span *ngIf="message.answer.content"
                        [innerHTML]="removeReferencePageLinkPlaceholders(message.answer.content) "
                        (click)="handlePageClick($event)">
                  </span>
                </div>

                <div>
                   <span *ngFor="let pagelink of message.answer.references">
                   <button class="btn btn-xs btn-outline-secondary" (click)="goToPDFPageNumber(pagelink.pageNumber)">
                     Page {{pagelink.pageNumber}}
                  </button>
                  </span>
                </div>

                <div *ngIf="message.answer.content && (message.answer.pending!==true)" style="color: #525050;">
                  Please note that it can sometimes be difficult to process the data accurately.
                  I advise checking and reading the document manually for the most precise information.
                </div>
              </div>
            </div>
          </div>

          <div class="form-inline chat-send-button-wrapper">
            <input type="text" [(ngModel)]="inputTextMessage" (keydown.enter)="sendMessage()" [disabled]="!isChatLoaded"
                   placeholder="Type your message..."
                   class="form-control"
                   aria-label="Type something... "
                   style="width: 90%">
            <span *ngIf="retrievingChatAnswer" class="subtask-loader" style="position: unset;width: 22px;height: 22px;"></span>
            <div class="input-group-append" style="margin-left: auto">
              <button [disabled]="!messagesHistory" (click)="sendMessage()" class="btn btn-outline-primary send-button "
                      type="button">
                <fa-icon [icon]="faArrowUp"></fa-icon>
              </button>
            </div>
          </div>

          <div class="chat-attachement-preview-pane">
            <div class="att-image-wrapper" style="border: dashed 1px gray" *ngIf="snippedImg">
              <fa-icon [icon]="faCross" class="float-right cursor-pointer fa-attachment-pane-close-icon"
                       (click)="snippedImg=null"></fa-icon>
              <img *ngIf="snippedImg" src="{{ snippedImg }}" class="snipped-img-preview"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<ngx-capture class="ngx-capture" [target]="screen" *ngIf="isSnippingModeActive"
             (resultImage)="saveSnippedImage($event)">
</ngx-capture>
